import { Navigate, Outlet, RouteObject } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { FC, useEffect, useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { DeepRequired, useForm } from 'react-hook-form'
import {
  IconEye,
  IconEyeOff,
  IconPoint,
  IconPointFilled,
  IconPointOff,
  IconSettings,
  IconTrash,
} from '@tabler/icons-react'
import { useCurrentOrgSafe } from '../../../../hooks/useCurrentOrgSafe.ts'
import { gqlClient } from '../../../../auth'
import { graphql } from '../../../../gql'
import {
  ContextPropertyType,
  ContextPropertyUsageIndicator,
  ContextRole,
  ProjectContextPropertiesListQuery,
} from '../../../../gql/graphql.ts'
import { Table } from '../../../../components/component/Table/Table.tsx'
import { Badge } from '../../../../components/component/Badge/Badge.tsx'
import { Tooltip } from '../../../../components/component/Tooltip/Tooltip.tsx'
import { Code } from '../../../../components/component/Code/Code.tsx'
import { Dropdown } from '../../../../components/component/DropdownMenu/DropdownMenu.tsx'
import {
  ContextPropertyTypeMap,
  ContextPropertyTypes,
  useHideProperty,
  useShowProperty,
} from '../../../../queries/contextProperties.ts'
import {
  confirm,
  confirmDelete,
  openModal,
} from '../../../../components/component/Modal/Modal.tsx'
import { FormSubmitButtons } from '../../../../components/component/FormSubmitButtons/FormSubmitButtons.tsx'
import { InputWrapper } from '../../../../components/input/InputWrapper/InputWrapper.tsx'
import { TextInput } from '../../../../components/input/TextInput/TextInput.tsx'
import {
  ArrayTextInput,
  SelectInput,
} from '../../../../components/input/SelectInput/SelectInput.tsx'
import { LongTextInput } from '../../../../components/input/LongTextInput/LongTextInput.tsx'
import Case from 'case'
import { useFormSubmitMutation } from '../../../../hooks/useFormSubmitMutation.ts'
import { toast } from 'react-hot-toast'
import { useFormDefaultValue } from '../../../../hooks/useFormDefaultValue.ts'
import { Truncate } from '../../../../components/component/Truncate/Truncate.tsx'
import { FormattedDate } from 'react-intl'
import { Tabs } from '../../../../components/component/Tabs/Tabs.tsx'
import { settingsRoute } from './Settings.tsx'

type Row =
  DeepRequired<ProjectContextPropertiesListQuery>['project']['contextProperties'][0]

export const PropertyForm = ({
  id,
  defaultName = '',
  onCreated,
}: {
  id?: string
  defaultName?: string
  onCreated?: (id: string) => void
}) => {
  const { project } = useCurrentOrgSafe()

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    clearErrors,
    formState: { touchedFields, isSubmitting, isDirty },
  } = useForm({
    defaultValues: {
      description: '',
      key: Case.camel(defaultName),
      name: defaultName,
      type: ContextPropertyType.String,
      meta: { options: [] } as { options: { value: string }[] },
    },
  })

  const { data, isLoading } = useFormDefaultValue({
    control,
    queryKey: ['context', id, 'edit form default value'],
    request: graphql(`
      query contextPropertyEditDefaultValue($id: ID!) {
        contextProperty(id: $id) {
          id
          name
          description
          key
          type
          meta
          builtIn
        }
      }
    `),
    variables: { id: id as string },
    skip: !id,
    mapDefaultValues: (data) => ({
      description: data.contextProperty?.description ?? '',
      key: data.contextProperty?.key ?? '',
      name: data.contextProperty?.name ?? '',
      type: data.contextProperty?.type ?? ContextPropertyType.String,
      meta: {
        options:
          data.contextProperty?.meta.options?.map(({ value }: any) => value) ??
          [],
      },
    }),
  })

  const name = watch('name')
  const type = watch('type')

  useEffect(() => {
    if (!touchedFields.key && !id) {
      setValue('key', Case.camel(name))
      clearErrors('key')
    }
  }, [clearErrors, name, setValue, touchedFields.key, id])

  const queryClient = useQueryClient()

  const submit = useFormSubmitMutation({
    control,
    mutation: id
      ? graphql(`
          mutation editContextProperty(
            $id: ID!
            $data: UpdateContextPropertyData!
          ) {
            contextProperty(id: $id) {
              update(data: $data) {
                id
              }
            }
          }
        `)
      : graphql(`
          mutation createContextProperty(
            $id: ID!
            $data: CreateContextPropertyData!
          ) {
            project(id: $id) {
              createContextProperty(data: $data) {
                id
              }
            }
          }
        `),
    mapVariables: (data) => ({
      id: id ?? project.id,
      data: {
        ...data,
        meta: {
          options: data.meta.options.map((value) => ({ value })),
        },
      },
    }),
    onSuccess: (data) => {
      if (!id) {
        // @ts-ignore
        onCreated?.(data.project.createContextProperty.id)
      }
      toast.success(id ? 'Property updated' : 'Property created')
      queryClient.invalidateQueries({ queryKey: ['context'] })
    },
  })

  return (
    <form
      onSubmit={handleSubmit(submit)}
      style={{ opacity: isLoading ? 0.5 : 1 }}
    >
      <InputWrapper
        control={control}
        name="name"
        component={TextInput}
        label="Name"
        autoFocus
        rules={{ required: true }}
      />
      <InputWrapper
        control={control}
        name="key"
        tooltip={
          data?.contextProperty?.builtIn
            ? 'You cannot change the key of a built-in property'
            : 'A developer friendly name to use inside your code'
        }
        className="monospace"
        component={TextInput}
        label="Key"
        disabled={data?.contextProperty?.builtIn}
        rules={{ required: true }}
      />
      <InputWrapper
        control={control}
        name="type"
        // @ts-ignore
        component={SelectInput}
        label="Value"
        options={ContextPropertyTypes.map(
          ({ type, name, description, Icon }) => ({
            value: type,
            label: name,
            tooltip: description,
            icon: Icon,
          })
        )}
        tooltip={
          data?.contextProperty?.builtIn
            ? 'You cannot change the value type of a built-in property'
            : undefined
        }
        disabled={data?.contextProperty?.builtIn}
      />
      {type === ContextPropertyType.Select && (
        <InputWrapper
          label="Options"
          name="meta.options"
          // @ts-ignore
          component={ArrayTextInput}
          tooltip="The list of possible values, will be used to guide users while setting up a flag"
          control={control}
        />
      )}
      <InputWrapper
        label="Description"
        name="description"
        component={LongTextInput}
        control={control}
      />
      <FormSubmitButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        reset={reset}
        labelSubmit={id ? 'Update property' : 'Create property'}
      />
    </form>
  )
}

const Usage: FC<{
  firstReceivedAt?: string | null
  lastReceivedAt?: string | null
  usage: ContextPropertyUsageIndicator
}> = ({ usage, lastReceivedAt, firstReceivedAt }) => {
  if (usage === ContextPropertyUsageIndicator.InUse) {
    return (
      <Tooltip>
        <Tooltip.Trigger
          component={Badge}
          color="success"
          children={
            <>
              <IconPointFilled size={16} /> Live
            </>
          }
        />
        <Tooltip.Content>
          Live since{' '}
          <FormattedDate
            value={firstReceivedAt ?? undefined}
            month="short"
            day="numeric"
            year="numeric"
          />
        </Tooltip.Content>
      </Tooltip>
    )
  }

  if (usage === ContextPropertyUsageIndicator.RecentlyStopped) {
    return (
      <Tooltip>
        <Tooltip.Trigger
          component={Badge}
          color="warning"
          children={
            <>
              <IconPointOff size={14} /> Stopped
            </>
          }
        />
        <Tooltip.Content>
          Last received{' '}
          <FormattedDate
            value={lastReceivedAt ?? undefined}
            month="short"
            day="numeric"
            hour="2-digit"
            minute="2-digit"
          />
        </Tooltip.Content>
      </Tooltip>
    )
  }

  if (usage === ContextPropertyUsageIndicator.Outdated) {
    return (
      <Tooltip>
        <Tooltip.Trigger
          component={Badge}
          color="error"
          children={
            <>
              <IconPointOff size={14} /> Legacy
            </>
          }
        />
        <Tooltip.Content>
          Last received{' '}
          <FormattedDate
            value={lastReceivedAt ?? undefined}
            month="short"
            day="numeric"
            hour="2-digit"
            minute="2-digit"
          />
        </Tooltip.Content>
      </Tooltip>
    )
  }

  if (usage === ContextPropertyUsageIndicator.NeverSeen) {
    return (
      <Tooltip>
        <Tooltip.Trigger
          component={Badge}
          children={
            <>
              <IconPoint size={16} /> Unknown
            </>
          }
        />
        <Tooltip.Content>This property was never received</Tooltip.Content>
      </Tooltip>
    )
  }

  return <></>
}

const Context = () => {
  const { project, organization } = useCurrentOrgSafe()

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['context', project.id, 'table'],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query projectContextPropertiesList($id: ID!) {
            project(id: $id) {
              id
              contextProperties {
                id
                name
                type
                description
                builtIn
                hidden
                key
                usedByFlags {
                  id
                  name
                }
                usage {
                  firstReceivedAt
                  lastReceivedAt
                  usage
                }
              }
            }
          }
        `),
        {
          id: project.id,
        }
      ),
  })

  const queryClient = useQueryClient()

  const deleteProperty = useMutation({
    mutationFn: (id: string) =>
      gqlClient.request(
        graphql(`
          mutation deleteContextProperty($id: ID!) {
            contextProperties(ids: [$id]) {
              delete
            }
          }
        `),
        { id }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['context'] })
    },
  })

  const hideProperty = useHideProperty()
  const showProperty = useShowProperty()

  const columns = useMemo<ColumnDef<Row>[]>(
    () => [
      {
        id: 'hidden',
        accessorFn: (row) => row.hidden,
        header: 'Visibility',
        meta: {
          hidden: true,
          renderFilter: (value) => (value ? 'Hidden' : 'Visible'),
        },
      },
      {
        id: 'name',
        accessorFn: (row) => row.name,
        header: 'Name',
        enableColumnFilter: false,
        enableHiding: false,
        cell: ({ row }) => (
          <div>
            <div>
              <em>{row.original.name}</em>{' '}
              {row.original.builtIn && (
                <Tooltip>
                  <Tooltip.Trigger component={Badge} children="Built-in" />
                  <Tooltip.Content>
                    Automatically added to the context if not sent by your app
                  </Tooltip.Content>
                </Tooltip>
              )}{' '}
              {row.original.hidden && (
                <Tooltip>
                  <Tooltip.Trigger
                    component={Badge}
                    color="error"
                    children={
                      <>
                        <IconEyeOff size={12} /> Hidden
                      </>
                    }
                  />
                  <Tooltip.Content>
                    Cannot be selected when setting up a flag
                  </Tooltip.Content>
                </Tooltip>
              )}
            </div>
            <small>
              <Truncate text={row.original.description} length={130} />
            </small>
          </div>
        ),
        meta: {
          flex: '2.5 1 150px',
        },
      },
      {
        id: 'slug',
        accessorFn: (row) => row.key,
        header: 'Key',
        enableColumnFilter: false,
        cell: ({ row }) => <Code value={row.original.key} />,
        meta: {
          flex: '1 1 150px',
        },
      },
      {
        id: 'type',
        accessorFn: (row) => row.type,
        header: 'Type',
        enableSorting: false,
        cell: ({ row }) => {
          const TypeIcon = ContextPropertyTypeMap[row.original.type]?.Icon
          return (
            <Badge color="primary">
              {TypeIcon && <TypeIcon size={12} />}
              {ContextPropertyTypeMap[row.original.type]?.name ??
                row.original.type}
            </Badge>
          )
        },
        meta: {
          flex: '0.2 1 120px',
          renderFilter: (value: keyof typeof ContextPropertyTypeMap) => {
            const TypeIcon = ContextPropertyTypeMap[value]?.Icon
            return (
              <Badge color="primary">
                {TypeIcon && <TypeIcon size={12} />}
                {ContextPropertyTypeMap[value]?.name ?? value}
              </Badge>
            )
          },
        },
        minSize: 120,
      },
      {
        id: 'usage',
        accessorFn: (row) => row.usedByFlags?.length,
        header: 'Usage',
        enableColumnFilter: false,
        cell: ({ row }) =>
          row.original.usedByFlags?.length ? (
            <>{row.original.usedByFlags.length} flags</>
          ) : (
            '-'
          ),
        meta: {
          flex: '0.2 1 85px',
        },
        minSize: 85,
      },
      {
        id: 'status',
        header: 'Status',
        accessorFn: (row) => row.usage.usage,
        enableSorting: false,
        enableGlobalFilter: false,
        cell: ({ row }) => <Usage {...row.original.usage} />,
        meta: {
          flex: '0 0 130px',
          renderFilter: (value) => {
            if (value === ContextPropertyUsageIndicator.InUse) {
              return (
                <Badge color="success">
                  <IconPointFilled size={16} /> Live
                </Badge>
              )
            }

            if (value === ContextPropertyUsageIndicator.RecentlyStopped) {
              return (
                <Badge color="warning">
                  <IconPointOff size={14} /> Stopped
                </Badge>
              )
            }

            if (value === ContextPropertyUsageIndicator.Outdated) {
              return (
                <Badge color="error">
                  <IconPointOff size={14} /> Legacy
                </Badge>
              )
            }

            if (value === ContextPropertyUsageIndicator.NeverSeen) {
              return (
                <Badge>
                  <IconPoint size={16} /> Unknown
                </Badge>
              )
            }

            return null
          },
        },
        minSize: 130,
      },
    ],
    []
  )

  return (
    <>
      <Table<Row>
        data={(data?.project?.contextProperties as Row[]) ?? []}
        loading={isLoading}
        fetching={isFetching}
        columns={columns}
        unit="properties"
        defaultSorting={[{ id: 'name', desc: false }]}
        defaultColumnFilters={[{ id: 'hidden', value: [false] }]}
        onCreate={
          organization.myAggregatedRole.context === ContextRole.Manage
            ? () =>
                openModal({
                  title: 'New Context Property',
                  content: <PropertyForm />,
                })
            : undefined
        }
        moreActions={
          organization.myAggregatedRole.context === ContextRole.Manage
            ? ({ row }) => (
                <>
                  <Dropdown.Item
                    icon={IconSettings}
                    label="Edit"
                    onClick={() =>
                      openModal({
                        title: 'Edit Context Property',
                        content: <PropertyForm id={row.original.id} />,
                      })
                    }
                  />
                  {row.original.hidden ? (
                    <Dropdown.Item
                      icon={IconEye}
                      label="Show"
                      onClick={() => showProperty(row.original.id)}
                    />
                  ) : (
                    <Dropdown.Item
                      icon={IconEyeOff}
                      label="Hide"
                      tooltip="Hide this property when setting up a flag"
                      onClick={() => hideProperty(row.original.id)}
                    />
                  )}
                  <hr />
                  <Dropdown.Item
                    icon={IconTrash}
                    label="Delete"
                    danger
                    disabled={
                      (row.original.builtIn ||
                        row.original.usedByFlags?.length > 0) &&
                      row.original.hidden
                    }
                    tooltip={
                      row.original.builtIn && row.original.hidden
                        ? 'Cannot delete built-in properties'
                        : row.original.usedByFlags?.length > 0 &&
                          row.original.hidden
                        ? 'This property is used by some flags'
                        : null
                    }
                    onClick={() => {
                      if (row.original.builtIn) {
                        confirm({
                          title: 'Cannot delete built-in properties',
                          description: (
                            <p>
                              Built-in properties cannot be deleted, but you can
                              hide them if you wish to simplify the interface
                              while setting up a flag.
                            </p>
                          ),
                          confirmText: 'Hide instead',
                        }).then(() => hideProperty(row.original.id))
                      } else if (row.original.usedByFlags?.length > 0) {
                        confirm({
                          title: 'This property is in use',
                          description:
                            row.original.usedByFlags?.length > 1 ? (
                              <>
                                <p>
                                  This property is used by the following flags:
                                </p>
                                <ul>
                                  {row.original.usedByFlags.map((flag) => (
                                    <li>{flag.name}</li>
                                  ))}
                                </ul>
                                <p>
                                  You can either update these flags and delete
                                  this property then, or hide this property now
                                  to simplify setting up new flags.
                                </p>
                              </>
                            ) : (
                              <>
                                <p>
                                  This property is used by the flag "
                                  {row.original.usedByFlags[0].name}".
                                </p>
                                <p>
                                  You can either update this flag and delete
                                  this property then, or hide this property now
                                  to simplify setting up new flags.
                                </p>
                              </>
                            ),
                          confirmText: 'Hide instead',
                        }).then(() => hideProperty(row.original.id))
                      } else {
                        confirmDelete({
                          unit: 'properties',
                          onDelete: () =>
                            deleteProperty.mutateAsync(row.original.id),
                        })
                      }
                    }}
                  />
                </>
              )
            : undefined
        }
      />
    </>
  )
}

const ContextHeader = () => {
  return (
    <>
      <h1>Context</h1>
      <Tabs
        tabs={[
          {
            label: 'Properties',
            to: 'properties',
          },
          {
            label: 'Settings',
            to: 'settings',
          },
        ]}
      />
      <Outlet />
    </>
  )
}

export const contextRoute: RouteObject = {
  path: 'context',
  element: <ContextHeader />,
  children: [
    settingsRoute,
    {
      path: 'properties',
      element: <Context />,
    },
    {
      path: '',
      element: <Navigate to="properties" />,
    },
  ],
}
