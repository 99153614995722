import { Outlet, RouteObject } from 'react-router-dom'
import { flagsRoute, NewFlagForm } from './flags/Flags.tsx'
import { contextRoute } from './context/Context.tsx'
import { apiKeysRoute } from './ApiKeys.tsx'
import style from './Overview.module.scss'
import { Card } from '../../../components/component/Card/Card.tsx'
import { DailyRequests } from '../settings/Usage/Usage.tsx'
import { useCurrentOrgSafe } from '../../../hooks/useCurrentOrgSafe.ts'
import { useQuery } from '@tanstack/react-query'
import { gqlClient, useUser } from '../../../auth'
import { graphql } from '../../../gql'
import { Table } from '../../../components/component/Table/Table.tsx'
import { FC, useEffect, useMemo, useState } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import {
  LastFlagsUpdateQuery,
  OrganizationStatus,
  SlackIntegrationStatus,
} from '../../../gql/graphql.ts'
import { DeepRequired } from 'react-hook-form'
import { ShortDate } from '../../../components/component/ShortDate/ShortDate.tsx'
import Balancer from 'react-wrap-balancer'
import { Button } from '../../../components/component/Button/Button.tsx'
import requestsStyle from '../settings/Usage/Usage.module.scss'
import launchImage from '../../../assets/empty-state/launch.svg'
import { openModal } from '../../../components/component/Modal/Modal.tsx'
import { IconArrowRight } from '@tabler/icons-react'
import { CheckList } from '../../../components/component/CheckList/CheckList.tsx'
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts'
import { TooltipProps } from 'recharts/types/component/Tooltip'
import {
  flip,
  FloatingPortal,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react'
import cx from 'classnames'
import tooltipStyle from '../../../components/component/MonitoringChart/MonitoringChart.module.scss'
import { useFlag } from 'react-tggl-client'
import { SimpleTooltip } from '../../../components/component/MonitoringChart/MonitoringTooltip.tsx'

const Project = () => {
  return <Outlet />
}

type Row = DeepRequired<LastFlagsUpdateQuery>['project']['lastUpdatedFlags'][0]

const shortDateFormatter = Intl.DateTimeFormat('en-GB', {
  day: 'numeric',
  month: 'short',
})
const longDateFormatter = Intl.DateTimeFormat('en-GB', {
  day: 'numeric',
  month: 'long',
})

const numberFormatter = Intl.NumberFormat('en-GB')

export const HealthTooltip: FC<TooltipProps<any, any>> = ({
  active,
  payload,
  label,
  labelFormatter = (label) => label,
}) => {
  const [reference, setReference] = useState<Element>()
  const { refs, floatingStyles, update } = useFloating({
    elements: { reference },
    placement: 'bottom-start',
    middleware: [
      offset({ mainAxis: 8, crossAxis: 8 }),
      shift({ padding: 8 }),
      flip({ padding: 8 }),
    ],
  })

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setReference({
        getBoundingClientRect() {
          return {
            width: 0,
            height: 0,
            x: event.clientX,
            y: event.clientY,
            left: event.clientX,
            right: event.clientX,
            top: event.clientY,
            bottom: event.clientY,
          }
        },
      } as Element)
      update()
    }

    document.addEventListener('mousemove', handleMouseMove)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [update])

  if (active && payload && payload?.length) {
    const value = payload[0].payload
    return (
      <FloatingPortal>
        <div
          className={tooltipStyle.graphTooltip}
          ref={refs.setFloating}
          style={floatingStyles}
        >
          <div className={tooltipStyle.date}>
            {label ? labelFormatter(label, payload) : null}
          </div>
          <div className={cx(tooltipStyle.series)}>
            <div
              className={tooltipStyle.color}
              style={{ background: '#4caf50' }}
            />
            <div className={tooltipStyle.name}>Healthy</div>
            <div className={tooltipStyle.value}>
              {numberFormatter.format(value.healthy)}
            </div>
          </div>
          <div
            className={cx(
              tooltipStyle.series,
              tooltipStyle.subSeries,
              !value.active && tooltipStyle.zero
            )}
          >
            <div className={tooltipStyle.name}>Active</div>
            <div className={tooltipStyle.value}>
              {numberFormatter.format(value.active)}
            </div>
          </div>
          <div
            className={cx(
              tooltipStyle.series,
              tooltipStyle.subSeries,
              !value.permanent && tooltipStyle.zero
            )}
          >
            <div className={tooltipStyle.name}>Permanent</div>
            <div className={tooltipStyle.value}>
              {numberFormatter.format(value.permanent)}
            </div>
          </div>
          <hr />
          <div className={cx(tooltipStyle.series)}>
            <div
              className={tooltipStyle.color}
              style={{ background: '#f44336' }}
            />
            <div className={tooltipStyle.name}>Action required</div>
            <div className={tooltipStyle.value}>
              {numberFormatter.format(value.unhealthy)}
            </div>
          </div>
          <div
            className={cx(
              tooltipStyle.series,
              tooltipStyle.subSeries,
              !value.new && tooltipStyle.zero
            )}
          >
            <div className={tooltipStyle.name}>New</div>
            <div className={tooltipStyle.value}>
              {numberFormatter.format(value.new)}
            </div>
          </div>
          <div
            className={cx(
              tooltipStyle.series,
              tooltipStyle.subSeries,
              !value.completed && tooltipStyle.zero
            )}
          >
            <div className={tooltipStyle.name}>Completed</div>
            <div className={tooltipStyle.value}>
              {numberFormatter.format(value.completed)}
            </div>
          </div>
          <div
            className={cx(
              tooltipStyle.series,
              tooltipStyle.subSeries,
              !value.stuck && tooltipStyle.zero
            )}
          >
            <div className={tooltipStyle.name}>Stuck</div>
            <div className={tooltipStyle.value}>
              {numberFormatter.format(value.stuck)}
            </div>
          </div>
          <div
            className={cx(
              tooltipStyle.series,
              tooltipStyle.subSeries,
              !value.legacy && tooltipStyle.zero
            )}
          >
            <div className={tooltipStyle.name}>Legacy</div>
            <div className={tooltipStyle.value}>
              {numberFormatter.format(value.legacy)}
            </div>
          </div>
          <hr />
          <div className={cx(tooltipStyle.series)}>
            <div className={tooltipStyle.name}>Total</div>
            <div className={tooltipStyle.value}>
              {numberFormatter.format(value.total)}
            </div>
          </div>
        </div>
      </FloatingPortal>
    )
  }

  return null
}

export const FlagsCount = ({ projectId }: { projectId: string }) => {
  const flagStatusFlag = useFlag('flagLastValues')

  const { data } = useQuery({
    queryKey: ['project', projectId, 'flagCount'],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query flagCountHistory($id: ID!) {
            project(id: $id) {
              id
              flagsCount {
                date
                active
                permanent
                completed
                legacy
                new
                stuck
                total
              }
            }
          }
        `),
        { id: projectId }
      ),
  })

  const graphData = useMemo(() => {
    return (
      data?.project?.flagsCount.map((row) => ({
        ...row,
        healthy: row.permanent + row.active,
        unhealthy: row.total - row.permanent - row.active,
      })) ?? []
    )
  }, [data])

  return (
    <Card variant="outlined" style={{ marginBottom: 20 }}>
      {flagStatusFlag.active && (
        <a
          href="https://tggl.io/help/concepts/technical-debt#the-lifecycle-stages"
          target="_blank"
          style={{ float: 'right' }}
        >
          Learn more
        </a>
      )}
      <h2>{flagStatusFlag.active ? 'Flags health' : 'Flags count'}</h2>
      <ResponsiveContainer width="100%" height={200}>
        <AreaChart
          data={graphData}
          margin={{ top: 5, right: 5, bottom: 0, left: 5 }}
        >
          <defs>
            <linearGradient id="totalFill" x1="0" y1="0" x2="0" y2="1">
              <stop offset="15%" stopColor="#5785FC" stopOpacity={0.21} />
              <stop offset="95%" stopColor="#B8C2F9" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="healthyFill" x1="0" y1="0" x2="0" y2="1">
              <stop offset="15%" stopColor="#4caf50" stopOpacity={0.3} />
              <stop offset="95%" stopColor="#4caf50" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="unhealthyFill" x1="0" y1="0" x2="0" y2="1">
              <stop offset="15%" stopColor="#f44336" stopOpacity={0.3} />
              <stop offset="95%" stopColor="#f44336" stopOpacity={0} />
            </linearGradient>
          </defs>
          <Tooltip
            labelFormatter={(date) => longDateFormatter.format(new Date(date))}
            content={
              flagStatusFlag.active ? <HealthTooltip /> : <SimpleTooltip />
            }
            cursor={{ stroke: '#e7e7e7' }}
          />
          <XAxis
            dataKey="date"
            tickFormatter={(value) =>
              shortDateFormatter.format(new Date(value))
            }
            axisLine={false}
            tickLine={false}
          />
          {flagStatusFlag.active ? (
            <>
              <Area
                stackId="1"
                type="monotone"
                dataKey="unhealthy"
                name="Action required"
                stroke="#f44336"
                strokeWidth={3}
                strokeLinecap="round"
                fill="url(#unhealthyFill)"
              />
              <Area
                stackId="1"
                type="monotone"
                dataKey="healthy"
                name="Healthy"
                stroke="#4caf50"
                strokeWidth={3}
                strokeLinecap="round"
                fill="url(#healthyFill)"
              />
            </>
          ) : (
            <Area
              type="monotone"
              dataKey="total"
              name="Number of flags"
              stroke="#575efc"
              strokeWidth={3}
              strokeLinecap="round"
              fill="url(#totalFill)"
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
    </Card>
  )
}

const Overview = () => {
  const { project, organization } = useCurrentOrgSafe()
  const user = useUser()
  const flagsCountGraphFlag = useFlag('flagsCountGraph')

  const { data, isLoading } = useQuery({
    queryKey: ['project', project.id, 'last flags update'],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query lastFlagsUpdate($projectId: ID!) {
            project(id: $projectId) {
              lastUpdatedFlags {
                id
                createdBy {
                  id
                  name
                }
                createdAt
                flag {
                  id
                  name
                }
              }
            }
          }
        `),
        { projectId: project.id }
      ),
  })

  const { data: organizationData } = useQuery({
    queryKey: ['organization', organization.id, 'checklist'],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query orgChecklist($organizationId: ID!) {
            organization(id: $organizationId) {
              slackIntegration {
                status
              }
              membersLimit {
                current
              }
              flagsLimit {
                current
              }
              requestsLimit {
                current
              }
            }
          }
        `),
        { organizationId: organization.id }
      ),
    enabled:
      organization.status === OrganizationStatus.Trial &&
      !organization.hideTrial,
  })

  const columns = useMemo<ColumnDef<Row>[]>(
    () => [
      {
        id: 'name',
        accessorFn: (row) => row.flag.name,
        header: 'Flag changed',
        enableColumnFilter: false,
        cell: ({ getValue }) => <em>{getValue() as string}</em>,
      },
      {
        id: 'author',
        accessorFn: (row) => row.createdBy.name,
        header: 'Author',
        enableSorting: false,
      },
      {
        id: 'date',
        accessorFn: (row) => row.createdAt,
        header: 'Date',
        cell: ({ getValue }) => <ShortDate value={getValue() as number} ago />,
        enableColumnFilter: false,
      },
    ],
    []
  )

  if (
    organization.status === OrganizationStatus.Trial &&
    !organization.hideTrial
  ) {
    return (
      <div className={style.onboardingContainer}>
        <h1>Get started with Tggl</h1>
        <h2>Hello {user?.firstName} 👋</h2>
        <p>
          We want you to get the most out of your Tggl trial, here is a quick
          onboarding checklist!
        </p>
        <CheckList
          items={
            !user?.role || user.role === 'developer'
              ? [
                  { completed: true, title: 'Create organization' },
                  {
                    completed:
                      (organizationData?.organization?.requestsLimit.current ??
                        1) > 0,
                    title: 'Install the SDK',
                    to: `/project/${project.slug}/api-keys`,
                    icon: IconArrowRight,
                    action: 'Install',
                  },
                  {
                    completed:
                      (organizationData?.organization?.flagsLimit.current ??
                        1) > 0,
                    title: 'Create your first flag',
                    to: `/project/${project.slug}/feature-flags`,
                    icon: IconArrowRight,
                    action: 'Create',
                  },
                  {
                    completed:
                      (organizationData?.organization?.membersLimit.current ??
                        1) > 1,
                    title: 'Invite your team mates',
                    to: '/settings/team',
                    icon: IconArrowRight,
                    action: 'Invite',
                  },
                  {
                    completed:
                      organizationData?.organization?.slackIntegration
                        .status === SlackIntegrationStatus.Enabled,
                    title: 'Link your Slack account',
                    to: '/settings/integrations',
                    icon: IconArrowRight,
                    action: 'Add to Slack',
                  },
                  {
                    completed: false,
                    title: 'Choose a plan',
                    to: '/settings/billing',
                    icon: IconArrowRight,
                    action: 'Billing',
                  },
                ]
              : [
                  { completed: true, title: 'Create organization' },
                  {
                    completed:
                      (organizationData?.organization?.flagsLimit.current ??
                        1) > 0,
                    title: 'Create your first flag',
                    to: `/project/${project.slug}/feature-flags`,
                    icon: IconArrowRight,
                    action: 'Create',
                  },
                  {
                    completed:
                      (organizationData?.organization?.membersLimit.current ??
                        1) > 1,
                    title: 'Invite someone from your tech team',
                    to: '/settings/team',
                    icon: IconArrowRight,
                    action: 'Invite',
                  },
                  {
                    completed:
                      organizationData?.organization?.slackIntegration
                        .status === SlackIntegrationStatus.Enabled,
                    title: 'Link your Slack account',
                    to: '/settings/integrations',
                    icon: IconArrowRight,
                    action: 'Add to Slack',
                  },
                  {
                    completed: false,
                    title: 'Choose a plan',
                    to: '/settings/billing',
                    icon: IconArrowRight,
                    action: 'Billing',
                  },
                ]
          }
        />
      </div>
    )
  }

  return (
    <>
      <h1>Overview</h1>
      <div className={style.container}>
        <div>
          <Table<Row>
            unit="flags"
            noToolbar
            loading={isLoading}
            rowTo={({ flag, id }) =>
              `/project/${project.slug}/feature-flags/${flag.id}/history/${id}`
            }
            defaultSorting={[{ id: 'date', desc: true }]}
            data={(data?.project?.lastUpdatedFlags as Row[]) ?? []}
            columns={columns}
            emptyState={
              <Card variant="outlined">
                <h2>Feature flags</h2>
                <div className={requestsStyle.requestsEmptyState}>
                  <img src={launchImage} />
                  <p>
                    <Balancer>
                      You have no flags yet. You can create your first flag and
                      start experimenting with it!
                    </Balancer>
                  </p>
                  <Button
                    color="primary"
                    inline
                    onClick={() =>
                      openModal({
                        title: 'New flag',
                        content: <NewFlagForm />,
                      })
                    }
                  >
                    Create my first flag
                  </Button>
                </div>
              </Card>
            }
          />
        </div>
        <div>
          {flagsCountGraphFlag.active && <FlagsCount projectId={project.id} />}
          <DailyRequests projectId={project.id} />
        </div>
      </div>
    </>
  )
}

export const projectRoute: RouteObject = {
  path: 'project/:projectSlug',
  element: <Project />,
  children: [
    flagsRoute,
    contextRoute,
    apiKeysRoute,
    {
      index: true,
      element: <Overview />,
    },
  ],
}
